<template>
  <el-dialog
    title="修改"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @close="onDialogClose"
    append-to-body
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="180px"
      class="edit-form"
      label-position="top"
      :disabled="submitting"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="名称(中文)" prop="name.cn">
            <el-input
              v-model="form.name.cn"
              placeholder="请输入"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="12">
          <el-form-item label="名称(英文)" prop="name.en">
            <el-input
              v-model="form.name.en"
              placeholder="请输入"
            ></el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="常规图" prop="descriptionImageUrl">
            <SingleImgUpload
              v-model="form.descriptionImageUrl"
            /> </el-form-item
        ></el-col>
        <el-col :span="12">
          <el-form-item label="反转色图" prop="reverseDescriptionImageUrl">
            <SingleImgUpload
              v-model="form.reverseDescriptionImageUrl"
            /> </el-form-item
        ></el-col>
      </el-row>

      <el-form-item label="次序" prop="seq">
        <el-input-number
          v-model="form.seq"
          label="请输入"
          :min="1"
          style="width:100%"
        ></el-input-number>
      </el-form-item>

      <el-form-item style="text-align: right">
        <!-- <el-button @click="onCancel('form')">关 闭</el-button> -->
        <el-button
          v-if="currentId !== null"
          type="warning"
          @click="submitEditForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
        <el-button
          v-if="currentId == null"
          type="primary"
          @click="submitForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { GetPartnersById, PutPartners, PostPartners } from "../api";
import { ShowApiError } from "@/request/error";
import SingleImgUpload from "@/components/SingleImgUpload";
export default {
  components: { SingleImgUpload },
  props: {
    sid: {
      type: [String, Number],
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentId: null,
      dialogVisible: false,
      loading: false,
      submitting: false,
      isExists: false,
      form: {},
      rules: {
        "name.cn": [
          {
            required: true,
            message: "请填写中文名称",
            trigger: ["blur", "change"],
          },
        ],
        "name.en": [
          {
            required: true,
            message: "请填写英文名称",
            trigger: ["blur", "change"],
          },
        ],
        descriptionImageUrl: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    sid: {
      handler(value) {
        if (value !== null) {
          this.currentId = Number(value);
          this.getFormData();
        } else {
          this.currentId = null;
          this.initForm();
        }
      },
      immediate: true,
    },
    show: {
      handler(value) {
        this.dialogVisible = value;
      },
      immediate: true,
    },
  },
  created() {
    this.initForm();
  },
  computed: {
    localeColumns() {
      return this.$store.getters.localeColumns;
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostPartners([
            {
              ...this.form,
            },
          ])
            .then(() => {
              this.$message.success("修改成功!");
              this.initForm();
              this.emitSucccess();
            })
            .catch((err) => {
              ShowApiError("保存失败", err);
            })
            .finally(() => {
              this.submitting = false;
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    submitEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutPartners([
            {
              id: this.currentId,
              ...this.form,
            },
          ])
            .then(() => {
              this.$message.success("修改成功!");
              this.initForm();
              this.getFormData();
              this.emitSucccess();
            })
            .catch((err) => {
              ShowApiError("保存失败", err);
            })
            .finally(() => {
              this.submitting = false;
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData() {
      this.loading = true;
      GetPartnersById(this.currentId)
        .then((res) => {
          let {
            name,
            reverseDescriptionImageUrl,
            descriptionImageUrl,
            seq,
          } = res.data;
          this.form = {
            descriptionImageUrl: descriptionImageUrl,
            reverseDescriptionImageUrl: reverseDescriptionImageUrl,
            name: name,
            seq: seq,
          };
          delete this.form.name.i18n;
          this.loading = false;
        })

        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单数据失败", err);
        });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    initForm() {
      this.form = {
        descriptionImageUrl: null,
        reverseDescriptionImageUrl: null,
        name: { ...this.localeColumns },
        seq: 0,
      };
    },
    onDialogClose() {
      this.dialogVisible = false;
      this.isExists = false;
      this.$emit("close", this.dialogVisible);
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-form {
  max-width: 100%;
  padding: 0 20px;
  .form-select {
    width: 100%;
  }
}
</style>
